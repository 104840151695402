import gql from "graphql-tag";

import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";

// get all users with role employee
export const WORK_AREAS = gql`
  query workAreas($tenantId: uuid!) {
    work_area(where: { tenant_id: { _eq: $tenantId } }) {
      ...WorkAreaBase
    }
  }
  ${workAreaBaseFragment.workAreaBase}
`;
