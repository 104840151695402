import gql from "graphql-tag";

// delete employee
export const DELETE_WORK_AREA = gql`
  mutation deleteWorkArea($workAreaId: uuid!, $tenantId: uuid!) {
    delete_work_area(where: { id: { _eq: $workAreaId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
