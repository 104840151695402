import gql from "graphql-tag";

import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";

// update employee
export const UPDATE_WORK_AREA = gql`
  mutation updateWorkArea(
    $workAreaId: uuid!
    $tenantId: uuid!
    $name: String!
    $abbreviation: String!
    $color: String
  ) {
    update_work_area(
      where: { id: { _eq: $workAreaId }, tenant_id: { _eq: $tenantId } }
      _set: { name: $name, abbreviation: $abbreviation, color: $color }
    ) {
      returning {
        ...WorkAreaBase
      }
    }
  }
  ${workAreaBaseFragment.workAreaBase}
`;
