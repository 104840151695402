import gql from "graphql-tag";

import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";

// create new employee with contract
export const CREATE_WORK_AREA = gql`
  mutation createWorkArea($name: String!, $abbreviation: String!, $color: String!, $tenantId: uuid!) {
    insert_work_area_one(object: { name: $name, abbreviation: $abbreviation, color: $color, tenant_id: $tenantId }) {
      ...WorkAreaBase
    }
  }
  ${workAreaBaseFragment.workAreaBase}
`;
