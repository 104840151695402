import { computed } from "vue";
// Graphql
import useAuth from "@/api/auth/useAuth";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { WorkAreaQuery, WorkAreasQuery } from "@/graphql/types";
import { WORK_AREAS } from "@/graphql/workAreas/queries/workAreas";
import { WORK_AREA } from "@/graphql/workAreas/queries/workArea";
import { CREATE_WORK_AREA } from "@/graphql/workAreas/mutations/createWorkArea";
import { DELETE_WORK_AREA } from "@/graphql/workAreas/mutations/deleteWorkArea";
import { UPDATE_WORK_AREA } from "@/graphql/workAreas/mutations/updateWorkArea";
// other
import produce from "immer";

/**
 ***************************
 * Helper functions
 ***************************
 */

/**
 * Create abbreviation of max. first 3 letters of work area
 * @param name Work area name
 */
export function createAbbreviation(name: string | null) {
  if (name) {
    const abbreviation = name.substr(0, 3);
    return abbreviation.toUpperCase();
  } else return "";
}

/**
 ***************************
 * Store hook
 ***************************
 */

export default function useWorkAreaStore(options?: { workAreaId?: string; allWorkAreas?: boolean }) {
  const { tenantId } = useAuth();

  /**
   * Query all workAreas
   */

  const workAreasQueryVariables = computed(() => {
    return { tenantId: tenantId.value };
  });

  const { result: workAreasResult, loading: workAreasLoading, error: workAreasError } = useQuery<WorkAreasQuery>(
    WORK_AREAS,
    workAreasQueryVariables.value,
    () => ({
      enabled: options?.allWorkAreas === true
    })
  );
  const workAreas = useResult(workAreasResult, [], data => data.work_area);

  /**
   * Query single workArea
   */

  // query variables
  const workAreaQueryVariables = computed(() => {
    return { tenantId: tenantId.value, workAreaId: options?.workAreaId };
  });
  // get workArea
  const {
    result: workAreaResult,
    loading: workAreaLoading,
    error: workAreaError,
    onResult: onWorkAreaResult
  } = useQuery<WorkAreaQuery>(WORK_AREA, workAreaQueryVariables.value, () => ({
    enabled: options?.workAreaId != undefined
  }));
  const workArea = useResult(workAreaResult, null, data => data.work_area[0]);

  /**
   * Create workArea
   */

  const {
    mutate: createWorkArea,
    loading: createWorkAreaLoading,
    error: createWorkAreaError,
    onDone: onCreateWorkAreaSuccess
  } = useMutation(CREATE_WORK_AREA, {
    update: (cache, { data: { insert_work_area_one } }) => {
      // read workAreas data from cache
      // DON'T FORGET VARIABLES
      const workAreasData = cache.readQuery<WorkAreasQuery>({
        query: WORK_AREAS,
        variables: workAreasQueryVariables.value
      });

      // add workArea to users list
      const workAreasUpdate = produce(workAreasData?.work_area, draftState => {
        // add workArea to state
        draftState?.push(insert_work_area_one);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: WORK_AREAS,
        variables: workAreasQueryVariables.value,
        data: { work_area: workAreasUpdate }
      });
    }
  });

  const onCreateWorkArea = (name: string, abbreviation: string, color: string) => {
    createWorkArea({
      name: name,
      abbreviation: abbreviation,
      color: color,
      tenantId: tenantId.value
    });
  };

  /**
   * Delete workArea
   */

  const {
    mutate: deleteWorkArea,
    loading: deleteWorkAreaLoading,
    error: deleteWorkAreaError,
    onDone: onDeleteWorkAreaSuccess
  } = useMutation(DELETE_WORK_AREA, {
    update: (cache, { data: { delete_work_area } }) => {
      // read workAreas data from cache
      // DON'T FORGET VARIABLES
      const workAreasData = cache.readQuery<WorkAreasQuery>({
        query: WORK_AREAS,
        variables: workAreasQueryVariables.value
      });

      // get id
      const workAreaId = delete_work_area.returning.length > 0 ? delete_work_area.returning[0].id : null;

      // remove workArea to users list
      const workAreasUpdate = produce(workAreasData?.work_area, draft => {
        // add workArea to state
        return draft?.filter(workArea => workArea.id !== workAreaId);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: WORK_AREAS,
        variables: workAreasQueryVariables.value,
        data: { work_area: workAreasUpdate }
      });
    }
  });

  const onDeleteWorkArea = (workAreaId: string) => deleteWorkArea({ tenantId: tenantId.value, workAreaId: workAreaId });

  /**
   * Update workArea
   */

  const {
    mutate: updateWorkArea,
    loading: updateWorkAreaLoading,
    error: updateWorkAreaError,
    onDone: onUpdateWorkAreaSuccess
  } = useMutation(UPDATE_WORK_AREA);

  const onUpdateWorkArea = (name: string, abbreviation: string, color: string) => {
    updateWorkArea({
      name: name,
      abbreviation: abbreviation,
      color: color,
      workAreaId: options?.workAreaId,
      tenantId: tenantId.value
    });
  };

  // /**
  //  * Status
  //  */

  workArea;
  const loading = computed(() => {
    if (workAreasLoading.value === true) return true;
    else if (workAreaLoading.value === true) return true;
    else if (createWorkAreaLoading.value === true) return true;
    else if (deleteWorkAreaLoading.value === true) return true;
    else if (updateWorkAreaLoading.value === true) return true;
    else return false;
  });

  const error = computed(() => {
    if (workAreasError.value) return workAreasError.value;
    else if (workAreaError.value) return workAreaError.value;
    else if (createWorkAreaError.value) return createWorkAreaError.value;
    else if (deleteWorkAreaError.value) return deleteWorkAreaError.value;
    else if (updateWorkAreaError.value) return updateWorkAreaError.value;
    else return null;
  });

  /**
   * Other
   */

  return {
    // status
    loading,
    error,
    // queries
    workAreas,
    workArea,
    onWorkAreaResult,
    // create
    onCreateWorkArea,
    onCreateWorkAreaSuccess,
    // delete
    onDeleteWorkArea,
    onDeleteWorkAreaSuccess,
    // update
    onUpdateWorkArea,
    onUpdateWorkAreaSuccess
  };
}
