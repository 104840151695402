<template>
  <DetailsPage>
    <!-- Side navigation -->
    <template #sideNavigation>
      <DetailsLink
        :name="'Settings'"
        :routeNameList="['workAreaSettings']"
        :link="`/work-areas/${cleanUuid(workAreaId)}/settings`"
      >
        <template #icon>
          <!-- Heroicon name: cog -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </template>
      </DetailsLink>
      <DetailsLink
        :name="'Employees'"
        :routeNameList="['workAreaEmployees']"
        :link="`/work-areas/${cleanUuid(workAreaId)}/employees`"
      >
        <template #icon>
          <!-- Heroicon name: users -->

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </template>
      </DetailsLink>
      <DetailsLink
        :name="'Demand'"
        :routeNameList="['workAreaDemand', 'demandTemplateDetails']"
        :link="`/work-areas/${cleanUuid(workAreaId)}/demand`"
      >
        <template #icon>
          <!-- Heroicon name: presentation-chart-line -->

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
            />
          </svg>
        </template>
      </DetailsLink>
    </template>

    <template #sections>
      <slot></slot>
    </template>
    <template #side-list>
      <DetailsSideList :header="'Work Areas'" :caption="'Search for work area'">
        <template #items>
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
          <WorkAreaDetailsListItem />
        </template>
      </DetailsSideList>
    </template>
  </DetailsPage>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DetailsPage from "@/components/details/DetailsPage.vue";
import DetailsLink from "@/components/details/DetailsLink.vue";
import DetailsSideList from "@/components/details/DetailsSideList.vue";
import WorkAreaDetailsListItem from "./WorkAreaDetailsListItem.vue";
// other
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetails",
  components: {
    DetailsPage,
    DetailsLink,
    DetailsSideList,
    WorkAreaDetailsListItem
  },
  props: {
    workAreaId: {
      type: String,
      required: true
    }
  },
  setup() {
    return { cleanUuid };
  }
});
</script>
