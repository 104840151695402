import gql from "graphql-tag";

export const workAreaBaseFragment = {
  workAreaBase: gql`
    fragment WorkAreaBase on work_area {
      id
      name
      color
      abbreviation
    }
  `
};
