
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import PageLayout from "@/components/PageLayout.vue";
import WorkAreaDetails from "@/features/workAreas/details/WorkAreaDetails.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";
// other
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetailsPage",
  components: {
    PageLayout,
    WorkAreaDetails
  },
  setup() {
    // workArea id from link
    const {
      params: { workAreaId: workAreaUrlId }
    } = useRoute();
    const workAreaId = computed(() => repairUuid(workAreaUrlId));

    // get work area
    const { workArea } = useWorkAreaStore({ workAreaId: workAreaId.value });

    // get previous route, that was not workArea details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null || window.history.state.back.includes("work-areas")) {
        return "/work-areas";
      } else return window.history.state.back;
    });

    const headerText = computed(() => {
      if (workArea.value) return workArea.value.name;
      else return "";
    });

    return {
      headerText,
      workAreaId,
      prevRoute
    };
  }
});
