
import { defineComponent } from "vue";
import WorkAreaAvatar from "../WorkAreaAvatar.vue";
import { getRandomColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "DetailsSideListItem",
  components: { WorkAreaAvatar },
  setup() {
    return { getRandomColor };
  }
});
