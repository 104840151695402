
import { defineComponent } from "vue";
import DetailsPage from "@/components/details/DetailsPage.vue";
import DetailsLink from "@/components/details/DetailsLink.vue";
import DetailsSideList from "@/components/details/DetailsSideList.vue";
import WorkAreaDetailsListItem from "./WorkAreaDetailsListItem.vue";
// other
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "WorkAreaDetails",
  components: {
    DetailsPage,
    DetailsLink,
    DetailsSideList,
    WorkAreaDetailsListItem
  },
  props: {
    workAreaId: {
      type: String,
      required: true
    }
  },
  setup() {
    return { cleanUuid };
  }
});
