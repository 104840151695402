import gql from "graphql-tag";

import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";

// get employee by id
export const WORK_AREA = gql`
  query workArea($workAreaId: uuid!, $tenantId: uuid!) {
    work_area(where: { id: { _eq: $workAreaId }, tenant_id: { _eq: $tenantId } }) {
      ...WorkAreaBase
    }
  }
  ${workAreaBaseFragment.workAreaBase}
`;
