
import { computed, defineComponent } from "vue";
import { selectedColors, setColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "WorkAreaAvatar",
  props: {
    text: {
      type: String,
      requred: true,
      validator: (prop: string) => prop.length <= 3
    },
    color: {
      type: String,
      default: "blue",
      validator: (prop: string) => selectedColors.includes(prop)
    },
    size: {
      type: String,
      default: "md",
      validator: (prop: string) => ["xs", "sm", "md", "lg", "xl"].includes(prop)
    }
  },
  setup(props) {
    const sizeClasses = computed(() => {
      return {
        xs: "h-6 w-10 text-xs rounded-md",
        sm: "h-8 w-12 text-sm rounded-lg",
        md: "h-10 w-16 text-lg rounded-xl",
        lg: "h-12 w-20 text-2xl rounded-2xl",
        xl: "h-14 w-24 text-2xl rounded-3xl"
      }[props.size as "sm" | "md" | "lg"];
    });

    const colorClasses = computed(() => {
      return `bg-${setColor(props.color, 500)}`;
    });

    return { colorClasses, sizeClasses };
  }
});
